const bookieDefaults = [
  {
    name: '32red',
    betType: { stake: 20, betTypeName: 'single', order: 1, maxBets: 10 },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '14:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: '10bet',
    betType: { stake: 1, betTypeName: 'lucky15', order: 1, maxBets: 6 },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '14:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: '888sport',
    betType: { stake: 50, betTypeName: 'single', order: 1, maxBets: 3 },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'agcomms',
    betType: {
      stake: 0,
      betTypeName: '',
      order: 0,
      maxBets: 0,
    },
    days: [],
    startTime: '',
    endTime: '',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: true,
      vbDisplay: true,
      analytics: false,
      accountsToOpen: false,
    },
  },
  {
    name: 'akbets',
    betType: {
      stake: 20,
      betTypeName: 'double',
      order: 1,
      maxBets: 3,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'baroneracing',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '08:00',
    endTime: '14:20',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'bet365',
    betType: {
      stake: 2,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:20',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'bet600',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'betfairsportsbook',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'betfred',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 6,
    },
    days: [0, 1, 2, 3, 4, 5],
    startTime: '11:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'betgoodwin',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'betmgm',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:30',
    endTime: '11:10',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'betiton',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:10',
    endTime: '11:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'bettarget',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:10',
    endTime: '11:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'betuk',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:30',
    endTime: '11:10',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'betvictor',
    betType: {
      stake: 3,
      betTypeName: 'patent',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:50',
    endTime: '11:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'betway',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '11:00',
    endTime: '14:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: true,
      vbDisplay: false,
      analytics: false,
      accountsToOpen: false,
    },
  },
  {
    name: 'betzone',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'boylesports',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 1,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:20',
    endTime: '17:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'bresbet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 6,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'bwin',
    betType: {
      stake: 20,
      betTypeName: 'double',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'betwright',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 3,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '08:00',
    endTime: '14:20',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: true,
      analytics: false,
      accountsToOpen: false,
    },
  },
  {
    name: 'coral',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 12,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:30',
    endTime: '15:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'copybet',
    betType: {
      stake: 3,
      betTypeName: 'patent',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:10',
    endTime: '11:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: false,
      accountsToOpen: false,
    },
  },
  {
    name: 'dragonbet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 3,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '09:00',
    endTime: '14:20',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'fafabet',
    betType: {
      stake: 20,
      betTypeName: 'double',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'fitzdares',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'fsb',
    betType: {
      stake: 0,
      betTypeName: '',
      order: 0,
      maxBets: 0,
    },
    days: [],
    startTime: '',
    endTime: '',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: true,
      analytics: false,
      accountsToOpen: false,
    },
  },
  {
    name: 'gentlemanjim',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 3,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '08:00',
    endTime: '14:20',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'geoffbanks',
    betType: {
      stake: 3,
      betTypeName: 'patent',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '15:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'grosvenor',
    betType: { stake: 20, betTypeName: 'single', order: 1, maxBets: 10 },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'highbet',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '15:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'hopa',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '11:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'karamba',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '11:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'ladbrokes',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 8,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:30',
    endTime: '11:10',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'leovegas',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:30',
    endTime: '11:10',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'livescorebet',
    betType: {
      stake: 3,
      betTypeName: 'patent',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '15:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'luckster',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '15:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'moplay',
    betType: { stake: 1, betTypeName: 'patent', order: 1, maxBets: 2 },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '14:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'mrgreen',
    betType: {
      stake: 0,
      betTypeName: '',
      order: 0,
      maxBets: 0,
    },
    days: [],
    startTime: '',
    endTime: '',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'mrmega',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '11:30',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'mrplay',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '11:30',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'netbet',
    betType: {
      stake: 20,
      betTypeName: 'double',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '09:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'nagsbet',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'nrgbet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 3,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '08:00',
    endTime: '14:20',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'paddypower',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'parimatch',
    betType: {
      stake: 3,
      betTypeName: 'patent',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'partypoker',
    betType: {
      stake: 20,
      betTypeName: 'double',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'planetsportbet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 6,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '7:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'playbookgaming',
    betType: {
      stake: 0,
      betTypeName: '',
      order: 1,
      maxBets: 0,
    },
    days: [],
    startTime: '',
    endTime: '',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: true,
      analytics: false,
      accountsToOpen: false,
    },
  },
  {
    name: 'rhinobet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 3,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '08:00',
    endTime: '14:20',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'skybet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 6,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'sportingbet',
    betType: {
      stake: 20,
      betTypeName: 'double',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'sportingindex',
    betType: {
      stake: 2,
      betTypeName: 'patent',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '11:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'sportnation',
    betType: { stake: 1, betTypeName: 'patent', order: 1, maxBets: 2 },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '14:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'sportpesa',
    betType: {
      stake: 0,
      betTypeName: '',
      order: 0,
      maxBets: 0,
    },
    days: [],
    startTime: '',
    endTime: '',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'spreadex',
    betType: {
      stake: 20,
      betTypeName: 'single',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '14:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'starsports',
    betType: {
      stake: 20,
      betTypeName: 'double',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'talksportbet',
    betType: {
      stake: 3,
      betTypeName: 'patent',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:50',
    endTime: '11:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'thepools',
    betType: {
      stake: 20,
      betTypeName: 'double',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '12:10',
    endTime: '17:00',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'unibet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 6,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '16:30',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'vbet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '15:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'vickersbet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 3,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '14:10',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
  {
    name: 'virginbet',
    betType: {
      stake: 3,
      betTypeName: 'patent',
      order: 1,
      maxBets: 5,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '07:00',
    endTime: '21:00',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'williamhill',
    betType: {
      stake: 2,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 8,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '16:15',
    displayOnPage: {
      createAccounts: true,
      syndAccounts: true,
      vbDisplay: true,
      analytics: true,
      accountsToOpen: true,
    },
  },
  {
    name: 'yeeehaaabet',
    betType: {
      stake: 1,
      betTypeName: 'lucky15',
      order: 1,
      maxBets: 3,
    },
    days: [0, 1, 2, 3, 4, 5, 6],
    startTime: '08:00',
    endTime: '14:20',
    displayOnPage: {
      createAccounts: false,
      syndAccounts: false,
      vbDisplay: false,
      analytics: true,
      accountsToOpen: false,
    },
  },
];

const syndicateList = [
  { name: 'Aaron Holmes', blackList: true },
  { name: 'Adam Walthall', blackList: false },
  { name: 'Alan Fallows', blackList: false },
  { name: 'Alexandra ChappleHyam', blackList: true },
  { name: 'Alexandra Loizou', blackList: true },
  { name: 'Alexandria Chapman', blackList: true },
  { name: 'Alfie Slight', blackList: false },
  { name: 'Amanda ConnellyHayward', blackList: true },
  { name: 'Andrew McCulloch', blackList: true },
  { name: 'Andrew McCusker', blackList: true },
  { name: 'Andrew Smith', blackList: true },
  { name: 'Ann Campbell', blackList: true },
  { name: 'Ann Russell', blackList: false },
  { name: 'Anna Mylonas', blackList: false },
  { name: 'Ashley Williams', blackList: true },
  { name: 'Aviva Caller', blackList: false },
  { name: 'Bethany Howe', blackList: false },
  { name: 'Bethany Manton', blackList: true },
  { name: 'Billy Bearder', blackList: true },
  { name: 'Callum Brown', blackList: false },
  { name: 'Carl Palmer', blackList: true },
  { name: 'Cathrine Lounds', blackList: true },
  { name: 'Charles Clinch', blackList: false },
  { name: 'Charlotte Whittaker', blackList: false },
  { name: 'Charlotte WilliamsWright', blackList: false },
  { name: 'Chelsea Lake', blackList: false },
  { name: 'Chris Johnson', blackList: false },
  { name: 'Colin Wormleighton', blackList: true },
  { name: 'Dane Robinson', blackList: false },
  { name: 'David Kelly', blackList: true },
  { name: 'Dominic Abbott', blackList: true },
  { name: 'Dominic Walthall', blackList: true },
  { name: 'Elizabeth Darken', blackList: true },
  { name: 'Emma Clarke', blackList: false },
  { name: 'Euan Russell', blackList: false },
  { name: 'Evrol Campbell', blackList: false },
  { name: 'Ffion Walker', blackList: false },
  { name: 'Greg Hill', blackList: true },
  { name: 'Gavin Cuff', blackList: false },
  { name: 'Halina Kelly', blackList: false },
  { name: 'Harriett Starbuck', blackList: false },
  { name: 'Harrison Banks', blackList: false },
  { name: 'Hayley Porter', blackList: true },
  { name: 'Jade Simpson', blackList: true },
  { name: 'Jacob Cuff', blackList: false },
  { name: 'Jacob Whitt', blackList: false },
  { name: 'James Hardie', blackList: false },
  { name: 'James Petcher', blackList: true },
  { name: 'Jason Woodward', blackList: true },
  { name: 'Jessica Frearson', blackList: false },
  { name: 'Jessica Sykes', blackList: false },
  { name: 'Jill Jones', blackList: false },
  { name: 'Joel Edgecumbe', blackList: false },
  { name: 'John Heeson', blackList: true },
  { name: 'Jonathan Hunt', blackList: false },
  { name: 'Joseph Pollard', blackList: false },
  { name: 'Joshua Smith', blackList: true },
  { name: 'Joshua Thorpe', blackList: false },
  { name: 'Joy Stevens', blackList: false },
  { name: 'Justine Brown', blackList: true },
  { name: 'Katie Dewis', blackList: false },
  { name: 'Karen Clarke', blackList: false },
  { name: 'Karen Holmes', blackList: false },
  { name: 'Karl Holmes', blackList: true },
  { name: 'Kay Lewis', blackList: false },
  { name: 'Kayleigh Palmer', blackList: true },
  { name: 'Kevin Delapena', blackList: true },
  { name: 'Kevin Heeley', blackList: false },
  { name: 'Kevin Storey', blackList: true },
  { name: 'Lauren Dobson', blackList: false },
  { name: 'Lee Hogan', blackList: false },
  { name: 'Leiona Connelly', blackList: true },
  { name: 'Linda Onorati', blackList: false },
  { name: 'Lisa Hancock', blackList: false },
  { name: 'Louise Blackshaw', blackList: true },
  { name: 'Lucie Holmes', blackList: true },
  { name: 'Lucy Porter', blackList: false },
  { name: 'Maggie Fallows', blackList: true },
  { name: 'Marcel Bechara', blackList: true },
  { name: 'Mark Elvin', blackList: true },
  { name: 'Mark Smyth', blackList: true },
  { name: 'Matthew Haines', blackList: false },
  { name: 'Matthew Jones', blackList: false },
  { name: 'Megan Hill', blackList: false },
  { name: 'Michael Fisher', blackList: false },
  { name: 'Michaela Coulling', blackList: true },
  { name: 'Nigel Odell', blackList: false },
  { name: 'Nicholas Long', blackList: true },
  { name: 'Nikki Tomlinson', blackList: false },
  { name: 'Ochirios Grant', blackList: false },
  { name: 'Paul Hearson', blackList: true },
  { name: 'Paula Hales', blackList: false },
  { name: 'Penelope Millar', blackList: false },
  { name: 'Peter Ward', blackList: false },
  { name: 'Rachel Branch', blackList: false },
  { name: 'Rachel Graham', blackList: false },
  { name: 'Rachel Northridge', blackList: false },
  { name: 'Rebecca Johnston', blackList: false },
  { name: 'Richard Moore', blackList: false },
  { name: 'Robert Dexter', blackList: false },
  { name: 'Robert Jones', blackList: true },
  { name: 'Robin Penrice', blackList: true },
  { name: 'Samantha Dye', blackList: false },
  { name: 'Samantha Smyth', blackList: false },
  { name: 'Sammi Moujahid', blackList: true },
  { name: 'Sarah Haddon', blackList: true },
  { name: 'Sarah Wright', blackList: false },
  { name: 'Seema Kachiwala', blackList: false },
  { name: 'Shaun Connelly', blackList: false },
  { name: 'Simon Collinson', blackList: true },
  { name: 'Sofia Spyrou', blackList: true },
  { name: 'Sophie Smyth', blackList: false },
  { name: 'Stavri Stavrou', blackList: true },
  { name: 'Stephanie Roome', blackList: false },
  { name: 'Steven Akinin', blackList: false },
  { name: 'Steven Edmonds', blackList: true },
  { name: 'Steven Whittaker', blackList: true },
  { name: 'Swati Nettleship', blackList: false },
  { name: 'Tegan Hall', blackList: false },
  { name: 'Thomas Bugg', blackList: false },
  { name: 'Thomas Parr', blackList: false },
  { name: 'Thomas Dickinson', blackList: true },
  { name: 'Thomas Townend', blackList: true },
  { name: 'Timothy Summers', blackList: true },
  { name: 'Tracey Horton', blackList: true },
  { name: 'Valeriia Bobrova', blackList: false },
  {
    blackList: true,
    name: 'Elaine Dixon',
  },
  {
    blackList: true,
    name: 'Scott Deacon',
  },
  {
    blackList: true,
    name: 'Ian Lilley',
  },
  {
    blackList: true,
    name: 'Paul Newell',
  },
  {
    blackList: true,
    name: 'Craig Henson',
  },
  {
    blackList: true,
    name: 'Christopher Ashton',
  },
  {
    blackList: true,
    name: 'Rainford Ricketts',
  },
  {
    blackList: true,
    name: 'James Nightingale',
  },
  {
    blackList: true,
    name: 'Lewis Noble',
  },
  {
    blackList: true,
    name: 'John Hales',
  },
  {
    blackList: true,
    name: 'Adam Shaw',
  },
  {
    blackList: true,
    name: 'Jack Malone',
  },
  {
    blackList: true,
    name: 'Craig Kerr',
  },
  {
    blackList: true,
    name: 'Donna Bailey',
  },
  {
    blackList: true,
    name: 'Ian Green',
  },
  {
    blackList: true,
    name: 'Phil Newell',
  },
  {
    blackList: true,
    name: 'Lee Hogg',
  },
  {
    blackList: true,
    name: 'Robert Dewick',
  },
  {
    blackList: true,
    name: 'Lesley Hirons',
  },
  {
    blackList: true,
    name: 'Maria Nikolaidou',
  },
  {
    blackList: false,
    name: 'Faith Phillips',
  },
];

const list = [
  'Elaine Dixon',
  'Scott Deacon',
  'Ian Lilley',
  'Paul Newell',
  'Craig Henson',
  'Christopher Ashton',
  'Rainford Ricketts',
  'James Nightingale',
  'Lewis Noble',
  'John Hales',
  'Adam Shaw',
  'Jack Malone',
  'Craig Kerr',
  'Donna Bailey',
  'Ian Green',
  'Phil Newell',
  'Lee Hogg',
  'Robert Dewick',
  'Lesley Hirons',
];

const formatted = list.map((i) => {
  return { name: i, blackList: true };
});
// pageType - which page is the dropdown going to be created for?
// syndAccounts / accountsToOpen / vbDisplay / analytics / createAccounts
export const bookieListGenerator = (pageType) => {
  const initialFilter = bookieDefaults.filter(
    (item) => item.displayOnPage[pageType]
  );
  let bookieArr = [];

  if (
    (pageType && pageType === 'syndAccounts') ||
    pageType === 'vbDisplay' ||
    pageType === 'analytics'
  ) {
    bookieArr = initialFilter.map((item) => item.name).sort();
    return bookieArr;
  }

  if (pageType && pageType === 'accountsToOpen') {
    bookieArr = initialFilter.map((item) => {
      const { name, betType } = item;
      const { stake, maxBets, betTypeName } = betType;
      const stakeMultiplier =
        betTypeName === 'lucky15'
          ? 15
          : betTypeName === 'patent'
          ? 7
          : betTypeName === 'trixie'
          ? 4
          : 1;

      return { name, stake: stake * stakeMultiplier * maxBets };
    });

    return bookieArr;
  }

  if (pageType && pageType === 'createAccounts') {
    const bookieObj = {};
    for (const item of initialFilter) {
      const { name, betType, days, startTime, endTime } = item;
      bookieObj[name] = { betType, days, startTime, endTime };
    }
    return bookieObj;
  }
};

export const syndicateListGenerator = (pageType) => {
  if (
    (pageType && pageType === 'finalAccounts') ||
    (pageType && pageType === 'accountsToOpen') ||
    (pageType && pageType === 'syndAccounts') ||
    (pageType && pageType === 'createAccounts')
  ) {
    const syndicateArray = syndicateList
      .filter((item) => item.blackList === false)
      .map((synd) => synd.name)
      .sort();
    return syndicateArray;
  }

  return syndicateList.map((item) => item.name).sort();
};
